<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="700"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card
          class="py-5 px-7 d-flex flex-column"
          min-height="400"
        >
          <v-card-title>
            <h2 class=''>{{ formTitle }}</h2>
          </v-card-title>

          <v-tabs
            class="flex-grow-0"
            v-model="selectedTab"
            centered grow
            color="primary"
          >
            <v-tab
              v-for="type in bubbleTypes"
              :key="type.type"
            >
              {{ type.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items
            class="pt-7"
            v-model="selectedTab"
            continuous
          >
            <!-- テキストバブル -->
            <v-tab-item>
              <v-form :ref="'form-' + bubbleType + bubbleRow[0].column_id"
                v-if="bubbleType === 'text' && bubbleRow.length === 1"
              >
                <v-row>
                  <v-col cols="12">
                    <icon-info icon="lightbulb-on-outline" :square="true">
                      テキストバブルはチャットで使われるテキストメッセージと同じです。
                    </icon-info>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea ref="textMessage"
                      v-model="bubbleRow[0].text"
                      filled
                      rows="7"
                      counter="1000"
                      label="メッセージ"
                      :rules="[valiRules.required, valiRules.max1000]"
                    ></v-textarea>
                  </v-col>
                  <v-menu
                    :close-on-content-click="false"
                    offset-x left top
                    transition="slide-y-reverse-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="mt-n10 mx-3"
                        text small
                        v-bind="attrs"
                        v-on="on"
                      >😀</v-btn>
                    </template>
                    <emoji-picker
                      locale="ja"
                      :i18n="emojiPickerJa"
                      :dataSource="emojiPickerSource"
                      @emojiClick="onTextMessageEmojiClick"
                    />
                  </v-menu>
                </v-row>
              </v-form>
              <v-sheet class="ma-5 mb-10 text--secondary"
                v-if="bubbleRow.length > 1"
              >
                テキストバブルに変更するためにはコンボバブルを一つにしてください
              </v-sheet>
            </v-tab-item>

            <!-- 画像バブル -->
            <v-tab-item>
              <v-form :ref="'form-' + bubbleType + bubbleRow[0].column_id"
                v-if="bubbleType === 'image' && bubbleRow.length === 1"
              >
                <v-row>
                  <v-col cols="12">
                    <icon-info icon="lightbulb-on-outline" class="mb-3" :square="true">
                      画像バブルは画像をそのまま送信します。
                    </icon-info>
                  </v-col>
                  <v-col cols="10">
                    <v-file-input
                      v-model="carouselFiles[0]"
                      accept="image/png, image/jpeg"
                      outlined chips show-size
                      prepend-icon="mdi-image"
                      label="画像をアップロード"
                      hint="サイズ上限：1MB" persistent-hint
                      :rules="[valiRules.required, valiRules.image]"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          color="primary"
                          label small
                        >
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="2"
                    class="py-0 d-flex flex-column align-center justify-start"
                    v-if="bubbleRow[0].image_url"
                  >
                    <small>設定中</small>
                    <v-img
                      max-height="50"
                      contain
                      :src="bubbleRow[0].image_url"
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-form>
              <v-sheet class="ma-5 mb-10 text--secondary"
                v-if="bubbleRow.length > 1"
              >
                画像バブルに変更するためにはコンボバブルを一つにしてください
              </v-sheet>
            </v-tab-item>

            <!-- コンボバブル -->
            <v-tab-item class="mb-3">
              <v-sheet
                v-if="bubbleType === 'buttons' || bubbleType === 'carousel'"
              >
                <icon-info icon="lightbulb-on-outline" class="mb-5" :square="true">
                  コンボバブルは画像・テキスト・リンクボタンを1つのバブル内に表示できます。<br />
                  横スクロールを追加すると、チャット画面上でバブル(吹き出し)が横並びに伸びていきます。
                </icon-info>
                <v-form :ref="'form-' + bubbleType + bubbleData.column_id"
                  v-for="(bubbleData, index) in bubbleRow"
                  :key="bubbleData.column_id"
                >
                  <v-row no-gutters
                    :class="[carouselClass]"
                  >
                    <v-badge
                      :offset-x="bubbleRow.length > 1 ? -1 : -10"
                      offset-y="-5"
                      left color="accent"
                      :content="index + 1"
                    ></v-badge>
                    <v-col cols="12">
                      <v-text-field
                        class="mt-0 pt-0"
                        v-model.trim="bubbleData.title"
                        label="タイトル"
                        counter="20"
                        required
                        :rules="[valiRules.required, valiRules.max20]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12"
                      class="mt-5"
                    >
                      <v-textarea ref="comboMessage"
                        v-model="bubbleData.text"
                        filled
                        rows="3" no-resize
                        counter="60"
                        label="メッセージ"
                        hint="注：60字以内でも3行目までしか表示されません"
                        :rules="[valiRules.required, valiRules.max60]"
                      ></v-textarea>
                      <v-menu
                        :close-on-content-click="false"
                        offset-x left top
                        transition="slide-y-reverse-transition"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mt-n14"
                            text small
                            v-bind="attrs"
                            v-on="on"
                            @click="carouselFocusIndex = index"
                          >😀</v-btn>
                        </template>
                        <emoji-picker
                          locale="ja"
                          :i18n="emojiPickerJa"
                          :dataSource="emojiPickerSource"
                          @emojiClick="onComboMessageEmojiClick"
                        />
                      </v-menu>
                    </v-col>

                    <!-- 画像 -->
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="10">
                          <v-file-input
                            v-model="carouselFiles[index]"
                            accept="image/png, image/jpeg"
                            outlined chips show-size
                            prepend-icon="mdi-image"
                            label="画像をアップロード"
                            hint="サイズ上限：1MB" persistent-hint
                            :rules="formType === 'create' ? [valiRules.required, valiRules.image] : [valiRules.image]"
                          >
                            <template v-slot:selection="{ text }">
                              <v-chip
                                color="primary"
                                label small
                              >
                                {{ text }}
                              </v-chip>
                            </template>
                          </v-file-input>
                        </v-col>
                        <v-col cols="2"
                          class="py-0 d-flex flex-column align-center justify-start"
                          v-if="bubbleData.image_url"
                        >
                          <small>設定中</small>
                          <v-img
                            max-height="50"
                            contain
                            :src="bubbleData.image_url"
                          >
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model.trim="bubbleData.button_label"
                            label="リンクテキスト"
                            counter="10"
                            required
                            :rules="[valiRules.required, valiRules.max10]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model.trim="bubbleData.button_action"
                            label="リンク先アドレス"
                            placeholder="https://example.com"
                            counter="150"
                            required
                            :rules="[valiRules.required, valiRules.max150, valiRules.url]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- カルーセルカラム登録 -->
                    <v-col class="py-3"
                      v-if="bubbleRow.length > 1"
                    >
                      <v-card-actions class="pa-0 pb-1 d-flex justify-end">
                        <v-btn
                          depressed small
                          :color="bubbleData.create ? 'accent' : 'primary'"
                          @click="formCarouselSubmitted(index)"
                        >
                          <span
                            v-if="!loading"
                          >{{ formCarouselButton(bubbleData.create) }}</span>
                          <v-progress-circular
                            v-if="loading"
                            indeterminate
                            color="accent"
                          ></v-progress-circular>
                        </v-btn>
                        <v-btn
                          text small
                          color="primary"
                          @click="deleteCarouselColumn(index)"
                        >削除</v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>
            </v-tab-item>
          </v-tabs-items>

          <v-spacer></v-spacer>

          <v-card-actions class="px-0"
            :class="bubbleRow.length > 1 ? 'mt-n2' : 'mt-2'"
          >
            <v-btn
              v-if="bubbleType === 'buttons' || bubbleType === 'carousel'"
              depressed
              color="primary"
              @click="addCarouselColumn()"
            >横スクロールバブル追加</v-btn>
            <v-spacer></v-spacer>

            <v-btn
              v-if="bubbleRow.length === 1"
              depressed
              color="primary"
              @click="formSubmitted(bubbleRow[0])"
            >
              <span
                v-if="!loading"
              >{{ formButton }}</span>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="accent"
              ></v-progress-circular>
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="close"
            >
              閉じる
            </v-btn>
          </v-card-actions>

          <!-- ローダー -->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute top
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'
import { VuemojiPicker } from 'vuemoji-picker'
import emojiPickerJa from '@/plugins/emoji-picker-ja.json'

export default {
  components: {
    'icon-info': IconInfo,
    'emoji-picker': VuemojiPicker,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      bubbleRow: [],
      formType: '',
      carouselFiles: [],
      openForm: false,
      comeBack: {},
      successCallback: null,
      selectedTab: 0,
      bubbleTypes: [
        {name:'テキストバブル', type:'text'},
        {name:'画像バブル', type:'image'},
        {name:'コンボバブル', type:'buttons'}
      ],
      emojiPickerJa: emojiPickerJa,
      emojiPickerSource: 'https://cdn.jsdelivr.net/npm/emoji-picker-element-data@%5E1/ja/emojibase/data.json',
      carouselFocusIndex: 0,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    formTitle() {
      if (this.formType === 'update') return 'バブルの更新'
      else if (this.formType === 'add') return 'バブルの追加'
      else return 'メッセージの新規登録'
    },
    formButton() {
      if (this.formType === 'update') return '更新'
      else if (this.formType === 'add') return '追加'
      else return '登録'
    },
    formCarouselButton() {
      return isCreate => {
        if (isCreate) return '登録'
        else return '更新'
      }
    },
    bubbleType() {
      if (this.selectedTab === 2 && this.bubbleRow.length > 1) return 'carousel'
      else return this.bubbleTypes[this.selectedTab].type
    },
    tabNumber() {
      return type => {
        if (type === 'text') return 0
        if (type === 'image') return 1
        if (type === 'buttons') return 2
        if (type === 'carousel') return 2
      }
    },
    carouselClass() {
      if (this.bubbleRow.length > 1) return ['carousel', 'mb-7', 'px-6', 'pt-7']
      else return ''
    }
  },

  watch: {
    // openForm: function(now, old) {
    //   if (now !== old) {
    //     this.isFormChanged = false
    //   }
    // },
    // bubbleData: {
    //   handler: function(now, old) {
    //     if (Object.keys(old).length && now.message_id === old.message_id && now.bubble_id === old.bubble_id ) {
    //       this.isFormChanged = true
    //     }
    //   },
    //   deep: true
    // },
    // 'bubbleData.type': function(now) {
    //   console.log(now);
    //   if (now === 'text') {
    //     this.bubbleData.image_url = ''
    //   }
    // },
    // file: function() {
    //   this.isFormChanged = true
    // },
    // selectedTab: function(now) {
    //   this.bubbleType = this.bubbleTypes[now].type
    // },
  },

  created() {
    this.adminApi.setToken(this.serverToken)
  },

  methods: {
    open(handdownData) {
      this.formType = handdownData.formType
      this.successCallback = handdownData.successCallback
      this.comeBack = handdownData.comeBack

      if (this.formType === 'update') {
        // this.comeBack.bubble = handdownData.bubbleData
        handdownData.bubbleRow.map( row => this.bubbleRow.push({...row}) )
        this.selectedTab = this.tabNumber(this.bubbleRow[0].type)
      } else {
      //新規
        this.bubbleRow.push({
          message_id: this.formType === 'add' ? handdownData.bubbleRow[0].message_id : 0,
          bubble_id: this.formType === 'add' ? handdownData.bubbleRow[0].bubble_id + 1 : 1,
          column_id: 1,
          title: '',
          text: '',
          image_url: '',
          button_label: '',
          button_action: '',
          create: true
        })
        this.selectedTab = 0
      }
      this.openForm = true
    },

    close() {
      this.bubbleRow.length = 0
      this.successCallback = null
      this.loading = false
      this.openForm = false
      this.carouselFiles.length = 0
    },

    //カルーセルカラム追加
    addCarouselColumn() {
      if (this.bubbleRow.length >= 10) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.bubbleRow.push({
        message_id: this.bubbleRow[0].message_id,
        bubble_id: this.bubbleRow[0].bubble_id,
        column_id: this.bubbleRow[this.bubbleRow.length - 1].column_id + 1,
        title: '',
        text: '',
        image_url: '',
        button_label: '',
        button_action: '',
        create: true
      })
    },

    onTextMessageEmojiClick(detail) {
      this.$refs.textMessage.$refs.input.focus()
      document.execCommand('insertText', false, detail.unicode)
    },

    onComboMessageEmojiClick(detail) {
      this.$refs.comboMessage[this.carouselFocusIndex].$refs.input.focus()
      document.execCommand('insertText', false, detail.unicode)
    },

    //**************************************************
    //サブミット
    //**************************************************
    formSubmitted(recordData) {
      if (this.bubbleType === 'buttons' && !this.$refs['form-' + this.bubbleType + recordData.column_id][0].validate()) {
        this.snackbar.message = $literals.MESSAGE.validationFormInput
      }
      else if (this.bubbleType !== 'buttons' && !this.$refs['form-' + this.bubbleType + recordData.column_id].validate()) {
        this.snackbar.message = $literals.MESSAGE.validationFormInput
      }
      else {
        this.snackbar.message = ''
      }

      if (this.snackbar.message) {
        this.snackbar.color = 'warning'
        this.snackbar.open = true
        return
      }

      this.loading = true

      const formData = new FormData()
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('message_id', recordData.message_id)
      formData.append('bubble_id', recordData.bubble_id)
      formData.append('column_id', recordData.column_id)
      formData.append('type', this.bubbleType)
      formData.append('title', recordData.title || '')
      formData.append('text', recordData.text || '')
      formData.append('image_url', this.carouselFiles[0] || recordData.image_url)
      formData.append('button_type', recordData.button_type || 'uri')
      formData.append('button_label', recordData.button_label || '')
      formData.append('button_action', recordData.button_action || '')

      const method = this.formType === 'update' ? 'PUT' : 'POST'

      let apiPart = 'linecast/'
      if (this.formType === 'create') apiPart += 'create/message/'
      else if (this.formType === 'add') apiPart += 'create/bubble/'
      else if (this.formType === 'update') apiPart += 'update/' + recordData.id

      this.adminApi.apiReqWithData(method, apiPart, formData).then( response => {
        this.successCallback(response)
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.close() )
    },

    //**************************************************
    //カルーセルサブミット
    //**************************************************
    formCarouselSubmitted(index) {
      if (!this.$refs['form-' + this.bubbleType + this.bubbleRow[index].column_id][0].validate()) {
        this.snackbar.message = $literals.MESSAGE.validationFormInput
      } else {
        this.snackbar.message = ''

        for (let i = 0; i < index; i++) {
          if (this.bubbleRow[i].create) {
            this.snackbar.message = "横スクロールバブルの登録は上から順にしてください"
          }
        }
      }
      if (this.snackbar.message) {
        this.snackbar.color = 'warning'
        this.snackbar.open = true
        return
      }

      this.loading = true

      const recordData = this.bubbleRow[index]

      const formData = new FormData()
      formData.append('shop_id', this.shopData.shop_id)
      formData.append('message_id', recordData.message_id)
      formData.append('bubble_id', recordData.bubble_id)
      formData.append('column_id', recordData.column_id)
      formData.append('type', this.bubbleType)
      formData.append('title', recordData.title || '')
      formData.append('text', recordData.text || '')
      formData.append('image_url', this.carouselFiles[index] || recordData.image_url)
      formData.append('button_type', recordData.button_type || 'uri')
      formData.append('button_label', recordData.button_label || '')
      formData.append('button_action', recordData.button_action || '')

      const method = recordData.create ? 'POST' : 'PUT'

      let apiPart = 'linecast/'
      if (this.formType === 'create') apiPart += 'create/message/'
      else if (recordData.create) apiPart += 'create/column/'
      else if (!recordData.create) apiPart += 'update/' + recordData.id

      this.adminApi.apiReqWithData(method, apiPart, formData).then( response => {
        for (let prop in response) {
          recordData[prop] = response[prop]
        }

        this.bubbleRow.map( row => {
          row.message_id = response.message_id
          row.bubble_id = response.bubble_id
        })

        recordData.create = false
        this.formType = 'update'

        this.$emit('updatedCarousel', response)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //カルーセル削除
    //**************************************************
    deleteCarouselColumn(index) {
      if (this.bubbleRow[index].create) {
        this.bubbleRow.splice(index, 1)
        return
      }

      this.loading = true

      const apiPath = 'linecast/delete/column/' + this.bubbleRow[index].id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.$emit('deletedCarousel', this.bubbleRow[index])
        this.bubbleRow.splice(index, 1)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
.carousel {
  border: 1px solid grey;
  border-radius: 5px;
}
</style>
