<!-- ************************************* -->
<!-- ************ テンプレート ************* -->
<!-- ************************************* -->
<template>
  <v-container fluid class="content-wrap">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[
        {label:'', icon:'arrow-left-bold-box-outline', tip:'左スクロール', event:'scrollLeft'},
        {label:'', icon:'arrow-right-bold-box-outline', tip:'右スクロール', event:'scrollRight'},
        {label:'メッセージ', icon:'plus-box-multiple', tip:'新規メッセージ作成', event:'addClicked'}
      ]"
      @scrollLeft="scrollLeft"
      @scrollRight="scrollRight"
      @addClicked="openFormRegister('create')"
    >
      <template v-slot:content-info>
        ターゲットリーチ：{{ targetreach }} ／ 当月配信済：{{ consumption }}
      </template>
    </info-bar>

    <!-- 説明 -->
    <v-row>
      <v-col>
        <icon-info icon="lightbulb-on-outline" :square="true">
          メッセージは複数のバブル(吹き出し)の集合になります。1つのメッセージの中には最大5つのバブルが入れられ、中のバブル数に関係なく1メッセージ＝配信数1になります。
        </icon-info>
      </v-col>
    </v-row>

    <v-row
      class="pa-3"
      v-if="!messages.length"
    >
      <v-col>
        <v-card flat>
          <v-card-text>
            表示するデータがありません。<br />
            画面右上の「メッセージ<v-icon>mdi-plus-box-multiple</v-icon>」からメッセージを作成してください。
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- コンテンツボディー（既存メッセージリスト） -->
    <section id="message-list"
      v-if="messages.length"
    >
      <div class="pa-3">
        <!-- メッセージ -->
        <v-card class="message mr-5 pa-5 pb-3"
          v-for="(message, idx) in messages"
          :key="message[0][0].message_id"
        >
          <v-card-title class="pt-0 px-0">
            <h5>メッセージ {{ idx + 1 }}</h5>
          </v-card-title>

          <v-card-subtitle class="pt-0 px-0">
            <p class="text-body-2">
              配信日：{{ lastCastedDate(message[0][0].casted_at) }} ／
              <!-- 送信数：{{ messageStats[idx].delivered }} -->
            </p>
            <!-- <p class="text-body-2"
              v-if="message[0][0].casted_at"
            >
              {{ interactionStatsStr(idx) }}
            </p> -->
          </v-card-subtitle>

          <v-row>
            <!-- バブル（最大5） -->
            <v-col cols="12"
              v-for="(bubble, index) in message"
              :key="bubble.id"
            >
              <v-card
                class="bubble mb-3"
                flat
              >
                <v-card-subtitle class="pt-0 px-0 font-weight-bold">
                  バブル{{ index + 1 }}<span>（{{ bubbleTypeString(bubble) }}）</span>
                </v-card-subtitle>
                <!-- バブルとカルーセル -->
                <v-card-text
                  class="line-carousel pl-3 pr-0 pb-2 d-flex"
                  :class="bubble[0].type"
                >
                  <div class="line-bubble pa-3"
                    :class="{'mr-5': bubble.length > 1}"
                    v-for="column in bubble"
                    :key="column.column_id"
                  >
                    <figure class="hero" v-if="column.type !== 'text'">
                    <v-img
                      contain
                      :max-height="(column.type === 'buttons' || column.type === 'carousel') ? 225 : 'inherit'"
                      :src="column.image_url"
                      :title="column.title"
                    >
                    </v-img>
                    </figure>
                    <div class="body" v-if="column.type !== 'image'">
                      <h3 class="my-2" v-if="column.type === 'buttons' || column.type === 'carousel'">
                        {{ column.title }}
                      </h3>
                      <p
                        :class="{'combo-text': column.type === 'buttons' || column.type === 'carousel'}"
                        v-html="insertBrTag(column.text)"
                      ></p>
                      <ul class="mt-2 pa-0" v-if="column.type === 'buttons' || column.type === 'carousel'">
                        <li class="py-2" align="center">
                          <a :href="column.button_action" target="_blank">
                            {{ column.button_label }}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions class="px-0 d-flex justify-end">
                  <v-btn
                    depressed small
                    color="primary"
                    @click="openFormRegister('update', bubble)"
                  >更新</v-btn>
                  <v-btn
                    text small
                    color="primary"
                    @click="openModalDelete('bubble', bubble[0])"
                  >削除</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <!-- スピードダイヤル -->
          <v-speed-dial class="mt-n1 mr-n2"
            v-model="message.btn"
            absolute top right open-on-hover
            direction="bottom"
            transition="slide-y-transition"
          >
            <template v-slot:activator>
              <v-btn
                v-model="message.btn"
                fab small
                color="primary"
              >
                <v-icon v-if="message.btn">mdi-close</v-icon>
                <v-icon v-else>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <btn-tip
              :tip="message.length > 4 ? 'バブルは5個まで' : 'バブル追加'"
              icon="message-plus-outline"
              fab small
              elevation="7"
              :color="message.length > 4 ? 'grey' : 'primary'"
              @click="openFormRegister('add', message[message.length - 1])"
            ></btn-tip>
            <btn-tip
              tip="一括配信" icon="cellphone-nfc"
              fab small
              elevation="7"
              color="primary"
              @click="openModalBroadcast(message)"
            ></btn-tip>
            <btn-tip
              tip="指定配信" label="グ" icon="cellphone-nfc"
              fab small
              elevation="7"
              color="primary"
              @click="openFormMulticast(message)"
            ></btn-tip>
            <btn-tip
              tip="メッセージ削除" icon="delete"
              fab small
              elevation="7"
              color="primary"
              @click="openModalDelete('message', message[0][0])"
            ></btn-tip>
          </v-speed-dial>
        </v-card>
      </div>
    </section>

    <!-- 登録フォーム -->
    <form-register
      ref="formRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      @updatedCarousel="updateCarousel($event)"
      @deletedCarousel="deleteCarousel($event)"
    ></form-register>

    <!-- 指定配信フォーム -->
    <form-multicast
      ref="formMulticast"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
      @reset="$emit('reset')"
    >
    </form-multicast>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="true"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import $literals from "@/literals.js";
import { ApiTool, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'
import IconInfo from '@/components/_IconInfo.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import FormMulticast from '@/components/_FormMulticast.vue'
import FormRegister from '@/components/LinecastFormRegister.vue'

export default {
  components: {
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'btn-tip': BtnWithTip,
    'icon-info': IconInfo,
    'modal-confirm': ModalConfirm,
    'modal-delete': ModalDelete,
    'overlay-message': OverlayMessage,
    'form-multicast': FormMulticast,
    'form-register': FormRegister,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      isLineIntegrated: false,
      messages: [],
      messageStats: [],
      bubble: {},
      targetreachNum: 0,
      consumptionNum: 0,
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    consumption() {
      return typeof this.consumptionNum === 'number' ? '約' + this.consumptionNum + '通' : this.consumptionNum
    },
    targetreach() {
      return typeof this.targetreachNum === 'number' ? '約' + this.targetreachNum + '名' : this.targetreachNum
    },
    lastCastedDate() {
      return date => (date ? moment(date).format('M月D日') : '未配信')
    },
    bubbleTypeString() {
      return bubble => {
        switch (bubble[0].type) {
          case 'text':
            return 'テキストのみ'

          case 'image':
            return '画像のみ'

          case 'buttons':
            return 'コンボ'

          case 'carousel':
            return '横スクロール コンボ × ' + bubble.length
        }
      }
    },
    insertBrTag() {
      return str => str.replace(/\r?\n/g, '<br>')
    },
    interactionStatsStr() {
      return index => {
        if (!this.messageStats[index].failed) {
          return "開封数：" + this.messageStats[index].uniqueImpression +
                 " ／ クリック数：" + this.messageStats[index].uniqueClick
        }
      }
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.loading = true
      this.loadingMessage = 'メッセージ取得中・・・'

      this.getData()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false)
    }
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      await this.adminApi.getReqWithAuth('line-integration/').then( result => {
        this.isLineIntegrated = result.shop_id !== 0
      })

      if (this.isLineIntegrated) {
        await Promise.all([
          this.getMessages(),
          this.getMonthlyConsumption(),
          this.getTargetreach(),
        ])

        // this.setInteractionStats()
      } else {
        this.modalMessage = '<p>こちらの機能はLINE連携を設定してから利用可能になります。<br />'+
                            'サイドメニューの「店舗基本設定」→「LINE連携」から設定をしてください。</p>'
        this.$refs.overlayMessage.open()
      }
    },

    //スクロール
    scrollLeft() {
      if (document.querySelector('#message-list')) document.querySelector('#message-list').scrollLeft -= 200
    },
    scrollRight() {
      if (document.querySelector('#message-list')) document.querySelector('#message-list').scrollLeft += 200
    },

    //新規作成＆更新クリック
    openFormRegister(type, bubbleRow) {
      const formHanddown = {
        formType: type,
        bubbleRow: bubbleRow,
        successCallback: type === 'update' ? this.updateBubbleSuccess: this.createMessageSuccess,
        comeBack: { type: type }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //削除クリック
    openModalDelete(type, obj) {
      const text = type === 'bubble' ? '選択したバブルを' : 'メッセージと中のバブルを全て'
      this.modalMessage = '<p>' + text + '削除してよろしいですか？?</p>'

      const modalHanddown = {
        submitCallback: this.modalMessageOrBubble,
        comeBack: { type: type, deleteObj: obj }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //一括配信クリック
    openModalBroadcast(message) {
      if (message.length > 5) {
        this.snackbar = {...{color:'error', message: $literals.MESSAGE.errorLinecastBubbleOver, open: true}}
        return
      }
      this.modalMessage = 'メッセージを配信してよろしいですか？'

      const modalHanddown = {
        yesCallback: this.broadcastMessage,
        comeBack: { messageId: message[0][0].message_id },
        buttonLabel: '配信する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    },

    //指定配信クリック
    openFormMulticast(message) {
      if (message.length > 5) {
        this.snackbar = {...{color:'error', message: $literals.MESSAGE.errorLinecastBubbleOver, open: true}}
        return
      }

      const formHanddown = {
        submitCallback: this.multicastMessage,
        comeBack: { messageId: message[0][0].message_id },
      }
      this.$refs.formMulticast.open(formHanddown)
    },

    //**************************************************
    //**************************************************
    //                    API接続
    //**************************************************
    //**************************************************
    //既存メッセージ取得
    //**************************************************
    getMessages() {
      return this.adminApi.getReqWithAuth('linecast/').then( results => {
        if (!results || !results.length) return

        results.map( row => {
          this.messages.push(row)
          this.messageStats.push({ messageId: row[0][0].message_id, failed: false })
        })
      })
    },

    //**************************************************
    //ユーザー操作統計取得
    //**************************************************
    setInteractionStats() {
      this.messages.map( message => {
        if (message[0][0].casted_at) {
          const apiPartial = 'line/interaction-stats/' + message[0][0].message_id
          const index = this.messageStats.findIndex( stats => stats.messageId == message[0][0].message_id )

          this.adminApi.getReqWithAuth(apiPartial).then(result => {
            this.messageStats[index].overview = {...result.overview}
          })
          .catch(() => {
            this.messageStats[index].failed = true
          })
        }
      })
    },

    //**************************************************
    //新規メッセージ or バブル作成
    //**************************************************
    createMessageSuccess(response) {
      this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}

      const target = this.messages.find( message =>  message[0][0].message_id == response.message_id )
      if (target === undefined) {
        const newBubble = [response]
        this.messages.unshift([newBubble])
      } else {
        target.push([response])
      }
    },

    //**************************************************
    //メッセージ内バブル更新
    //**************************************************
    updateBubbleSuccess(response) {
      this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}

      this.messages.map( message => {
        if (message[0][0].message_id == response.message_id) {
          const bubbleIndex = message.findIndex( bubble => bubble[0].bubble_id == response.bubble_id )

          for (let prop in response) {
            message[bubbleIndex][0][prop] = response[prop]
          }
        }
      })
    },

    //**************************************************
    //カルーセルカラム更新
    //**************************************************
    updateCarousel(response) {
      this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}

      const targetM = this.messages.find( message =>  message[0][0].message_id == response.message_id )
      if (targetM === undefined) {
        const newBubble = [response]
        this.messages.unshift([newBubble])
      } else {
        const targetB = targetM.find( bubble => bubble[0].bubble_id == response.bubble_id )
        if (targetB === undefined) {
          targetM.push([response])
        } else {
          const targetC = targetB.find( column => column.column_id == response.column_id )
          if (targetC === undefined) {
            targetB.push(response)
          } else {
            for (let prop in response) {
              targetC[prop] = response[prop]
            }
          }
        }
      }
    },

    //**************************************************
    //カルーセルカラム削除
    //**************************************************
    deleteCarousel(deletedColumn) {
      this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}

      const targetM = this.messages.find( message =>  message[0][0].message_id == deletedColumn.message_id )
      if (targetM !== undefined) {
        const targetB = targetM.find( bubble => bubble[0].bubble_id == deletedColumn.bubble_id )
        if (targetB !== undefined) {
          const targetIndex = targetB.findIndex( column => column.column_id == deletedColumn.column_id )
          if (targetIndex > -1) targetB.splice(targetIndex, 1)
        }
      }
    },

    //**************************************************
    //メッセージ／バブル削除
    //**************************************************
    modalMessageOrBubble(cameBackData) {
      let apiPartial = 'linecast/delete/message/' + cameBackData.deleteObj.message_id
      if (cameBackData.type === 'bubble') apiPartial += '/bubble/' + cameBackData.deleteObj.bubble_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        if (cameBackData.type === 'bubble') {
          this.messages.map( (message, i) => {
            if (message[0][0].message_id === cameBackData.deleteObj.message_id) {
              // if only one bubble in a message, delete message
              if (message.length === 1) {
                this.messages.splice(i, 1)
              } else {
                const targetIndex = message.findIndex( bubble => bubble[0].bubble_id === cameBackData.deleteObj.bubble_id )
                if (targetIndex > -1) message.splice(targetIndex, 1)
              }
            }
          })
        } else {
          const targetIndex = this.messages.findIndex( message => message[0][0].message_id == cameBackData.deleteObj.message_id )
          if (targetIndex > -1) this.messages.splice(targetIndex, 1)
        }

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    },

    //**************************************************
    //一括配信
    //**************************************************
    broadcastMessage(cameBackData) {
      const apiPartial = 'linecast/broadcast/' + cameBackData.messageId

      this.adminApi.apiReqWithData('POST', apiPartial).then( result => {
        if (result === -1) {
          this.snackbar = {...{color:'error', message: $literals.MESSAGE.unsetLineIntegration, open: true}}
        } else if (result > 0) {
          this.snackbar = {...{color:'success', message: '一括配信完了しました\nターゲットリーチ：約' + result.data + '名', open: true}}
        } else {
          this.snackbar = {...{color:'info', message: '一括配信完了しました', open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalConfirm.close() )
    },

    //**************************************************
    //指定配信
    //**************************************************
    multicastMessage(cameBackData) {
      const apiPart = 'linecast/multicast/' + cameBackData.messageId
      const paylogroup = JSON.stringify(cameBackData.customerGroups)

      this.adminApi.apiReqWithData('POST', apiPart, paylogroup).then( result => {
        if (result === -1) {
          this.snackbar = {...{color:'error', message: $literals.MESSAGE.unsetLineIntegration, open: true}}
        } else if (result > 0) {
          this.snackbar = {...{color:'success', message: '指定配信完了しました\nターゲットリーチ：約' + result + '名', open: true}}
        } else {
          this.snackbar = {...{color:'info', message: '指定配信完了しました', open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formMulticast.close() )
    },

    //**************************************************
    //当月送信数取得
    //**************************************************
    getMonthlyConsumption() {
      return this.adminApi.getReqWithAuth('line/consumption/').then(result => {
        this.consumptionNum = result
      })
      .catch(() => this.consumptionNum = '取得失敗' )
    },

    //**************************************************
    //ターゲットリーチ取得
    //**************************************************
    getTargetreach() {
      return this.adminApi.getReqWithAuth('line/targetreach/').then(result => {
        this.targetreachNum = result
      })
      .catch(() => this.targetreachNum = '取得失敗' )
    },
  }
}
</script>

<!-- ************************************* -->
<!-- ************** スタイル ************** -->
<!-- ************************************* -->
<style scoped>
#message-list {
  overflow-x: scroll;
}
#message-list > div {
  white-space: nowrap;
}
.message {
  display: inline-block;
  white-space: normal;
  overflow: hidden;
  position: relative;
  max-width: 300px;
  vertical-align: top;
  font-size: 1.1em;
  border-radius: 3px;
}
.bubble.v-sheet {
  border: none;
}
::-webkit-scrollbar {
    height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 5px;
}
.line-carousel {
  overflow-x: scroll;
}
.line-bubble {
  position: relative;
  display: inline-block;
  width: 100%;
  min-height: 40px;
  border-radius: 15px;
}
.line-bubble:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -5px;
  left: -22px;
  border: 9px solid transparent;
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
}
.line-bubble {
  background-color: var(--content-bg-diff);
  color: #222 !important;
}
.line-bubble:after {
  border-right: 21px solid var(--content-bg-diff);
}
>>> .btn-bottom .v-btn {
  width: 100% !important;
}
.combo-text {
  overflow: hidden;
  max-height: 66px;
}
</style>
