<template>
  <v-container fluid
    v-if="openForm"
  >
    <v-row justify="center">
      <v-dialog
        v-model="openForm"
        max-width="500"
        persistent
        transition="dialog-bottom-transition"
      >
        <v-card class="py-5 px-10">
          <v-card-title class="px-0">
            <h2>LINEメッセージ指定配信</h2>
          </v-card-title>

          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-text class="px-0">指定配信する顧客グループを選択してください。</v-card-text>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="targetGroup"
                  label="顧客グループ（LINE友だち数）"
                  :items="customerGroups"
                  item-value="customer_group_id"
                  item-text="group_name"
                  item-color="primary"
                  required
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
              <v-col cols="12"
                class="my-3"
              >
                <icon-info icon="lightbulb-on-outline" class="text-subtitle-1" :square="true">
                  指定配信ではブラック設定された顧客は除外されます。
                </icon-info>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="formSubmitted"
              >
                送信
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-form>

          <!-- ローダー -->
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute top
            color="primary"
          ></v-progress-linear>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    'icon-info': IconInfo,
  },
  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      targetGroup: '',
      customerGroups: [],
      openForm: false,
      loading: false,
      valiRules: ValidationRules,
      valiRuleMax: function(v){ return v <= 500 || '配信先アカウント数が500を超えています' },
      valiRuleNone: function(v){ return v > 0 || '配信対象が0です' },
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)
  },

  methods: {
    //
    // API req: グループ取得
    //
    getCustomerGroups() {
      return this.adminApi.getReqWithAuth('customer-group/').then( groups => {
        if (!groups || !groups.length) return

        this.customerGroups = groups

        //アカウント数を取得
        return this.adminApi.getReqWithAuth('customer-group/customer-count/').then( counts => {
          if (!counts || !counts.length) return

          counts.map( count => {
            const target = this.customerGroups.find( group => group.customer_group_id === count.group_id )
            if (target !== undefined) {
              target.customer_count = count.customer_count
              target.group_name += ' (' + count.customer_count + '人)'
            }
          })
        })
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    open(handdownData) {
      if (!this.customerGroups.length) this.getCustomerGroups()

      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack
      this.openForm = true
    },

    close() {
      this.submitCallback = null
      this.loading = false
      this.openForm = false
    },

    formSubmitted() {
      if (!this.targetGroup || !this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }
      const target = this.customerGroups.find( group => group.customer_group_id === this.targetGroup)
      if (!target.customer_count) {
        this.snackbar = {...{color:'warning', message: 'こちらのグループには' + $literals.MESSAGE.infoNoTargetToCast, open: true}}
        return
      }

      this.loading = true
      this.comeBack.customerGroups = this.targetGroup

      if (this.submitCallback) {
        this.submitCallback(this.comeBack)
      }
    }
  }
}
</script>

<style scoped>
.preview {
  overflow-y: scroll;
}
</style>
